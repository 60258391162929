<template>
  <div class="root">
    <div class="search-box">
      <el-form ref="searchFormRef" :inline="true" :model="searchForm">
        <el-form-item v-for="(element, index) in searchElements" :key="index" :prop="element.model">
          <el-input class="default-select-width" v-if="element.type === 'input'" v-model="searchForm[element.model]" clearable :placeholder="element.placeholder"></el-input>
          <el-select class="default-select-width" v-if="element.type === 'select'" v-model="searchForm[element.model]" :placeholder="element.placeholder">
            <el-option v-for="item in element.options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-date-picker v-if="element.type === 'datetime'" v-model="searchForm[element.model]" :placeholder="element.placeholder" type="datetime" value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button :loading="tableLoading" type="primary" @click="searchTableData">查询</el-button>
          <el-button @click="resetFormFields('searchFormRef')">重置</el-button>
          <el-button type="success" @click="handleCreateDigEvent">创建营销会议</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table-box">
      <el-table :data="tableData" stripe style="width: 100%" v-loading="tableLoading">
        <template v-for="(column, index) in tableColumn">
          <el-table-column :key="index" :label="column.label" :min-width="column.minWidth" :show-overflow-tooltip="column.showOverflowTooltip ? true : false">
            <template slot-scope="scope">
              <span v-if="column.clipboard" class="clipboard" :data-clipboard-text="$helpers.v(scope.row, column.prop)">{{ $helpers.v(scope.row, column.prop) }}</span>

              <el-image :style="{height: '40px', width: '40px'}" v-else-if="column.image" :src="$helpers.v(scope.row, column.prop)"></el-image>

              <span v-else>
                {{$helpers.v(scope.row, column.prop)}}
                <br v-if="column.prop2">
                {{column.prop2 ? $helpers.v(scope.row, column.prop2) : ''}}
              </span>

            </template>
          </el-table-column>
        </template>
        <el-table-column label="操作" min-width="120" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="handleTableRowDetail(scope.row)">详情</el-button>
            <el-button type="text" @click="handleTableRowEdit(scope.row)">编辑</el-button>
            <el-button type="text" class="clipboard" :data-clipboard-text="scope.row.ecv_dig_path">路径</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginate-box">
      <pagination-component :pagination.sync="pagination" :getTableDataFunc="getTableData"></pagination-component>
    </div>

    <!-- 编辑或创建会议抽屉 -->
    <drawer-component :visible.sync="createOrUpdateVisible">
      <template slot="title">{{
        digEventId ? "编辑数字营销会议" : "创建数字营销会议"
      }}</template>
      <template slot="content">
        <dig-event-form-component :visible.sync="createOrUpdateVisible" :id="digEventId" :refreshTableDataFunc="getTableData"></dig-event-form-component>
      </template>
    </drawer-component>

    <!-- 查看会议详情 -->
    <drawer-component :visible.sync="digEventDetailVisible">
      <template slot="title">数字营销会议详情</template>
      <template slot="content">
        <dig-event-detail-component :id="digEventId"></dig-event-detail-component>
      </template>
    </drawer-component>
  </div>
</template>

<script>
import DrawerComponent from "../../components/DrawerComponent/DrawerComponent.vue";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent.vue";
import DigEventDetailComponent from "./components/DigEventDetailComponent.vue";
import DigEventFormComponent from "./components/DigEventFormComponent.vue";

export default {
  name: "DigEventList",
  components: {
    DrawerComponent,
    DigEventFormComponent,
    PaginationComponent,
    DigEventDetailComponent,
  },
  data() {
    return {
      searchElements: [
        { type: "input", model: "id", placeholder: "会议ID" },
        { type: "input", model: "title", placeholder: "会议标题" },
        { type: "datetime", model: "start_time_start", placeholder: "会议开始时间", },
        { type: "datetime", model: "end_time_end", placeholder: "会议结束时间", },
      ],
      searchForm: { id: "", title: "", start_time_start: "", end_time_end: "", },
      pagination: { page: 1, perPage: 15, total: 0 },
      tableLoading: false,
      tableColumn: [
        { prop: "id", label: "营销会议ID", minWidth: 100, clipboard: true },
        { prop: "event_id", label: "关联会议ID", minWidth: 100 },
        {
          prop: "dig_event_info.zh.title",
          prop2: "dig_event_info.en.title",
          label: "标题", minWidth: 240, showOverflowTooltip: true,
        },
        { prop: "category_text", label: "领域分类", minWidth: 140, showOverflowTooltip: true, },
        { prop: "industry_text", label: "会议分类", minWidth: 140, showOverflowTooltip: true, },
        { prop: "start_time", label: "开始时间", minWidth: 140 },
        { prop: "end_time", label: "结束时间", minWidth: 140 },
        { prop: "status_text", label: "状态", minWidth: 60 },
        { prop: "sell_status_text", label: "售卖状态", minWidth: 80 },
        { prop: "created_at", label: "创建时间", minWidth: 140 },
      ],
      tableData: [],
      createOrUpdateVisible: false,
      digEventDetailVisible: false,
      digEventId: 0,
    };
  },
  watch: {
    searchForm: {
      deep: true,
      handler() {
        this.searchTableData()
      }
    }
  },
  methods: {
    getTableData() {
      this.tableLoading = true;
      this.$api.digEvent
        .digEventList({ ...this.searchForm, ...this.pagination })
        .then((res) => {
          let { data } = res;
          this.tableData = data.data;

          this.pagination.total = data.total;

          this.tableLoading = false;
        });
    },
    searchTableData() {
      this.pagination.page = 1;
      this.getTableData();
    },
    resetFormFields(ref) {
      this.$refs[ref] && this.$refs[ref].resetFields();
      this.searchTableData();
    },
    handleTableRowDetail(row) {
      this.digEventId = row.id;
      this.digEventDetailVisible = true;
    },
    handleTableRowEdit(row) {
      this.digEventId = row.id;
      this.createOrUpdateVisible = true;
    },
    handleCreateDigEvent() {
      this.digEventId = 0;
      this.createOrUpdateVisible = true;
    }
  },
  created() {
    this.getTableData();
  },
};
</script>

<style scoped lang="scss">
</style>
