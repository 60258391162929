<template>
  <el-select :multiple="multiple" class="default-select-width" v-model="_industry_id" placeholder="请选择分类" :filterable="true" :clearable="true">
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "IndustrySelectComponent",
  props: {
    industry_id: {
      type: [Number, String, Array],
      default: ""
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    _industry_id: {
      get() {
        return this.industry_id;
      },
      set(val) {
        this.$emit("update:industry_id", val);
      },
    },
  },
  methods: {
    getIndustryOptions() {
      this.$api.industry.industryOptions().then((res) => {
        this.options = res.data;
      });
    },
  },
  created() {
    this.getIndustryOptions();
  },
};
</script>

<style scoped lang="scss">
</style>
