<template>
  <el-select
    class="default-select-width"
    v-model="_eventId"
    placeholder="请选择会议"
    :filterable="true"
    :clearable="true"
  >
    <el-option
      v-for="item in options"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    >
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: "EventSelectComponent",
  props: {
    eventId: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    _eventId: {
      get() {
        return this.eventId;
      },
      set(val) {
        this.$emit("update:eventId", val);
      },
    },
  },
  methods: {
    getEventOptions() {
      this.$api.event.eventOptions().then((res) => {
        this.options = res;
      });
    },
  },
  created() {
    this.getEventOptions();
  },
};
</script>

<style scoped lang="scss">
</style>
