<template>
  <div class="root">
    <vue-tinymce v-model="_value" :setting="setting"></vue-tinymce>
  </div>
</template>

<script>
import base from "../../api/base";
export default {
  name: "TinymceComponent",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    minHeight: {
      type: Number,
      default: 240,
    },
  },
  components: {},
  data() {
    return {
      setting: {
        menubar: false,
        toolbar:
          "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |",
        toolbar_drawer: "sliding",
        quickbars_selection_toolbar:
          "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        plugins: "link image media table lists fullscreen autoresize",
        language: "zh_CN",
        min_height: 240,
        placeholder: "",
        images_reuse_filename: false,
        images_upload_url: base.url + '/api/service/upload?type=image'
      },
    };
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("update:value", val);
      },
    },
  },
  methods: {},
  created() {
    this.setting.min_height = this.minHeight;
    this.setting.placeholder = this.placeholder;
  },
};
</script>

<style scoped lang="scss">
.root {
  z-index: 9999;
}
</style>
