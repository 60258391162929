<template>
  <div class="root" v-loading="contentLoading">
    <el-form ref="formRef" :model="formData" label-width="120px" v-loading="formLoading">
      <el-form-item label="关联会议">
        <event-select-component :eventId.sync="formData.event_id"></event-select-component>
      </el-form-item>
      <el-form-item label="领域分类">
        <category-select-component :category_id.sync="formData.category_id"></category-select-component>
      </el-form-item>
      <el-form-item label="会议分类">
        <industry-select-component :industry_id.sync="formData.industry_id"></industry-select-component>
      </el-form-item>
      <el-form-item label="会议开始时间">
        <el-date-picker v-model="formData.start_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择会议开始日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="会议结束时间">
        <el-date-picker v-model="formData.end_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择会议结束日期时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="formData.status">
          <el-radio :label="0">隐藏</el-radio>
          <el-radio :label="1">正常（发布状态）</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="售卖状态">
        <el-radio-group v-model="formData.sell_status">
          <el-radio :label="0">关闭</el-radio>
          <el-radio :label="1">开启</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="隐藏时间">
        <el-radio-group v-model="formData.hide_time">
          <el-radio :label="1">显示</el-radio>
          <el-radio :label="2">隐藏</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="虚购人数">
        <el-input-number v-model="formData.base_buy_count"></el-input-number>
        <span class="span-tip">升序排列，数字越小越靠前</span>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="formData.sort"></el-input-number>
        <span class="span-tip">升序排列，数字越小越靠前</span>
      </el-form-item>
      <el-form-item label="会议内容">
        <el-tabs active-name="zh" type="border-card">
          <el-tab-pane name="zh" label="中文">
            <el-form-item label="标题">
              <el-input v-model="formData.zh.title" placeholder="请输入中文标题"></el-input>
            </el-form-item>
            <el-form-item label="地址">
              <el-input v-model="formData.zh.address" placeholder="请输入中文地址"></el-input>
            </el-form-item>
            <el-form-item label="封面">
              <aliyun-upload-image-component :imageUri.sync="formData.zh.cover_image"></aliyun-upload-image-component>
            </el-form-item>
            <el-form-item label="介绍">
              <tinymce-component :value.sync="formData.zh.introduction"></tinymce-component>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane name="en" label="英文">
            <el-form-item label="标题">
              <el-input v-model="formData.en.title" placeholder="请输入英文标题"></el-input>
            </el-form-item>
            <el-form-item label="地址">
              <el-input v-model="formData.en.address" placeholder="请输入英文地址"></el-input>
            </el-form-item>
            <el-form-item label="封面">
              <aliyun-upload-image-component :imageUri.sync="formData.en.cover_image"></aliyun-upload-image-component>
            </el-form-item>
            <el-form-item label="介绍">
              <tinymce-component :value.sync="formData.en.introduction"></tinymce-component>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
      <el-form-item label="往届现场">
        <aliyun-upload-image-component :imageUri.sync="formData.sponsor_3"></aliyun-upload-image-component>
      </el-form-item>
      <el-form-item label="合作媒体">
        <aliyun-upload-image-component :imageUri.sync="formData.sponsor_2"></aliyun-upload-image-component>
      </el-form-item>
      <el-form-item label="赞助商">
        <aliyun-upload-image-component :imageUri.sync="formData.sponsor_1"></aliyun-upload-image-component>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="handleSave" :loading="saveButtonLoading">保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import EventSelectComponent from "../../../components/EventSelectComponent/EventSelectComponent.vue";
import AliyunUploadImageComponent from "../../../components/AliyunUploadImageComponent/AliyunUploadImageComponent";
import TinymceComponent from "../../../components/TinymceComponent/TinymceComponent";
import CategorySelectComponent from '../../../components/CategorySelectComponent/CategorySelectComponent.vue';
import IndustrySelectComponent from '../../../components/IndustrySelectComponent/IndustrySelectComponent.vue';

export default {
  components: {
    TinymceComponent,
    AliyunUploadImageComponent,
    EventSelectComponent,
    CategorySelectComponent,
    IndustrySelectComponent,
  },
  name: "DigEventFormComponent",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: true,
    },
    refreshTableDataFunc: {
      type: Function,
      default: function () {
      },
    },
  },
  data() {
    return {
      contentLoading: false,
      formLoading: false,
      saveButtonLoading: false,
      formData: {
        event_id: "",
        category_id: "",
        industry_id: "",
        platform: "ecv_digital",
        start_time: "",
        end_time: "",
        status: 0,
        sell_status: 0,
        hide_time: 1,
        base_buy_count: 0,
        sort: 0,
        sponsor_1: "",
        sponsor_2: "",
        sponsor_3: "",
        zh: {
          cover_image: "",
          title: "",
          address: "",
          introduction: "",
        },
        en: {
          cover_image: "",
          title: "",
          address: "",
          introduction: "",
        },
      },
    };
  },
  methods: {
    handleSave() {
      this.saveButtonLoading = true;

      this.$api.digEvent
        .createOrUpdate(this.formData)
        .then((res) => {
          this.saveButtonLoading = false;

          let {code, msg} = res;
          if (code !== 0) {
            this.$message.error(msg);
            return false;
          }
          this.$message.success("操作成功!");

          this.refreshTableDataFunc();
          this.$emit("update:visible", false);
        })
        .catch(() => {
          this.saveButtonLoading = false;
        });
    },
    getContentData() {
      this.contentLoading = true;
      this.$api.digEvent.digEventDetail({id: this.id}).then((res) => {
        let {data} = res;

        this.formData.event_id = data.event_id;
        this.formData.category_id = data.category_id;
        this.formData.industry_id = data.industry_id;
        this.formData.start_time = data.start_time;
        this.formData.end_time = data.end_time;
        this.formData.status = data.status;
        this.formData.sell_status = data.sell_status;
        this.formData.hide_time = data.hide_time;
        this.formData.base_buy_count = data.base_buy_count;
        this.formData.sort = data.sort;

        this.formData.zh.cover_image = data.dig_event_info.zh.cover_image;
        this.formData.zh.title = data.dig_event_info.zh.title;
        this.formData.zh.address = data.dig_event_info.zh.address;
        this.formData.zh.introduction = data.dig_event_info.zh.introduction;

        this.formData.en.cover_image = data.dig_event_info.en.cover_image;
        this.formData.en.title = data.dig_event_info.en.title;
        this.formData.en.address = data.dig_event_info.en.address;
        this.formData.en.introduction = data.dig_event_info.en.introduction;

        data.dig_event_sponsor.forEach((item) => {
          this.formData["sponsor_" + item.type] = item.image;
        });
        this.contentLoading = false;
      });
    },
  },
  created() {
    if (this.id) {
      this.formData.id = this.id;
      this.getContentData();
    }
  },
};
</script>

<style scoped lang="scss">
</style>
