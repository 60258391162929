<template>
  <div class="root">
    <div style="display:flex;">
      <el-upload accept=".png,.jpeg,.jpg,.gif" :action="uploadUrl" :data="extData" :multiple="false" :show-file-list="false" :before-upload="beforeUpload" :on-success="onSuccess" :on-error="onError">
        <el-button type="primary" :loading="uploadLoading">点击上传(OSS)</el-button>
      </el-upload>
      <el-button style="margin-left: 10px" type="default" v-show="_imageUri" @click="_imageUri = ''">清空</el-button>
      <el-input v-model="_imageUri" style="margin-left: 10px" placeholder="可直接填写图片地址"></el-input>
    </div>
    <div class="upload-image-box" v-show="_imageUri">
      <el-image class="upload-image" :src="_imageUri" :preview-src-list="[_imageUri]"></el-image>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

export default {
  name: "AliyunUploadImageComponent",
  props: {
    imageUri: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      uploadLoading: false,
      type: "image",
      uploadUrl: "",
      extData: {
        key: "",
        policy: "",
        OSSAccessKeyId: "",
        success_action_status: "200",
        signature: "",
      },
      fileUrl: "",
    };
  },
  computed: {
    _imageUri: {
      get() {
        return this.imageUri;
      },
      set(val) {
        this.$emit("update:imageUri", val);
      },
    },
  },
  methods: {
    beforeUpload(file) {
      let ext = file.name.split(".").pop();
      let fileName = uuidv4() + "." + ext;

      this.uploadLoading = true;

      return this.$api.service.uploadPolicy({ type: this.type }).then((res) => {
        let { code, msg, data } = res;
        if (code !== 0) {
          this.$message.error(msg);
          this.uploadLoading = false;
          return false;
        }
        let { accessid, dir, host, policy, signature } = data;
        this.uploadUrl = host;
        this.extData.OSSAccessKeyId = accessid;
        this.extData.key = dir + fileName;
        this.extData.policy = policy;
        this.extData.signature = signature;

        this.fileUrl = host + "/" + this.extData.key;
      });
    },
    onSuccess() {
      this.uploadLoading = false;
      this._imageUri = this.fileUrl;
    },
    onError() {
      this.uploadLoading = false;
      this.$message.error("抱歉, 服务器开小差了~");
    },
  },
};
</script>

<style scoped lang="scss">
.upload-image-box {
  margin-top: 10px;

  .upload-image {
    height: 120px;
    border: 1px dashed #204b7c;
    border-radius: 5px;
  }
}
</style>
