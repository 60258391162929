<template>
  <div class="root" v-loading="contentLoading">
    <el-descriptions :column="1" :labelStyle="{ width: '140px' }" border v-if="contentData">
      <template v-for="(column, index) in contentColumns">
        <el-descriptions-item :key="index" :label="column.label">
          <el-image style="height: 140px" v-if="column.element === 'image'" :src="$helpers.v(contentData, column.prop)" :preview-src-list="[$helpers.v(contentData, column.prop)]"></el-image>
          <div v-else-if="column.clipboard" class="clipboard" :data-clipboard-text="
              $helpers.v(contentData, column.prop)
            ">{{ $helpers.v(contentData, column.prop) }}
          </div>
          <div v-else>{{
              $helpers.v(contentData, column.prop)
            }}
          </div>
        </el-descriptions-item>
      </template>
      <el-descriptions-item label="介绍(中)">
        <div v-html="$helpers.v(contentData, 'dig_event_info.zh.introduction')"></div>
      </el-descriptions-item>
      <el-descriptions-item label="介绍(英)">
        <div v-html="$helpers.v(contentData, 'dig_event_info.en.introduction')"></div>
      </el-descriptions-item>
      <el-descriptions-item label="往届现场">
        <el-image style="height: 140px" v-if="sponsor_3" :src="sponsor_3" :preview-src-list="[sponsor_3]"></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="合作媒体">
        <el-image style="height: 140px" v-if="sponsor_2" :src="sponsor_2" :preview-src-list="[sponsor_2]"></el-image>
      </el-descriptions-item>
      <el-descriptions-item label="赞助商">
        <el-image style="height: 140px" v-if="sponsor_1" :src="sponsor_1" :preview-src-list="[sponsor_1]"></el-image>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: "DigEventDetailComponent",
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      contentColumns: [
        { label: "营销会议ID", prop: "id" },
        { label: "关联会议ID", prop: "event_id" },
        { label: "归属平台", prop: "platform" },
        { label: "标题(中)", prop: "dig_event_info.zh.title" },
        { label: "标题(英)", prop: "dig_event_info.en.title" },
        { label: "领域分类", prop: "category_text" },
        { label: "会议分类", prop: "industry_text" },
        { label: "地址(中)", prop: "dig_event_info.zh.address" },
        { label: "地址(英)", prop: "dig_event_info.en.address" },
        { label: "状态", prop: "status_text" },
        { label: "虚购人数", prop: "base_buy_count" },
        { label: "排序", prop: "sort" },
        {
          label: "封面(中)",
          prop: "dig_event_info.zh.cover_image",
          element: "image",
        },
        {
          label: "封面(英)",
          prop: "dig_event_info.en.cover_image",
          element: "image",
        },
      ],
      contentLoading: false,
      contentData: null,
      sponsor_1: "",
      sponsor_2: "",
    };
  },
  methods: {
    getContentData() {
      this.contentLoading = true;
      this.$api.digEvent.digEventDetail({ id: this.id }).then((res) => {
        this.contentData = res.data;

        this.contentData.dig_event_sponsor.forEach((item) => {
          this["sponsor_" + item.type] = item.image;
        });

        this.contentLoading = false;
      });
    },
  },
  created() {
    this.getContentData();
  },
};
</script>

<style scoped lang="scss">
.root {
  min-height: 30vh;
}
</style>
